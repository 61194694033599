import { SetupField, RunField } from 'graphql/generated/graphql';
import { ExpressionFieldOption } from '../../types';

const getFilteredFields = (
  fields: SetupField[] | RunField[],
  filterText: string,
  currentExpression: string,
  fieldType: 'setup' | 'run'
): ExpressionFieldOption[] => {
  const matches = currentExpression.match(/\{([^}]+)\}/g) || [];

  return fields
    .map(field => ({
      field: fieldType === 'setup'
        ? field as SetupField
        : field as RunField,
      matchScore: calculateMatchScore(field, filterText),
    }))
    .filter(option => {
      if (!isFieldCompatible(option.field, matches, fields)) {
        return false;
      }
      return option.matchScore > 0;
    })
    .sort((a, b) => b.matchScore - a.matchScore);
};

const calculateMatchScore = (field: SetupField | RunField, filterText: string): number => {
  if (!filterText) return 1;

  const name = field.name.toLowerCase();
  const filter = filterText.toLowerCase();

  if (name === filter) return 3;
  if (name.startsWith(filter)) return 2;
  if (name.includes(filter)) return 1;

  return 0;
};

const isFieldCompatible = (
  field: SetupField | RunField,
  existingMatches: string[],
  allFields: (SetupField | RunField)[],
): boolean => {
  if (existingMatches.length === 0) return true;

  const firstFieldName = existingMatches[0].replace(/[{}]/g, '');
  const firstField = allFields.find(f => f.name === firstFieldName);

  if (!firstField) return true;

  const firstPositions = getPositionSignature(firstField);
  const currentPositions = getPositionSignature(field);

  return firstPositions === currentPositions;
};

const getPositionSignature = (field: SetupField | RunField): string => {
  return (field.positions || [])
    .map(p => p.label)
    .sort()
    .join(',');
};

export default getFilteredFields;
