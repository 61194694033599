import { useRef, RefObject } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { InputGroup, InputGroupProps } from '@blueprintjs/core';

interface Props {
  controllerProps: UseControllerProps;
  inputProps?: InputGroupProps;
  autoFocus?: boolean;
  disabled?: boolean;
  inputRef?: RefObject<HTMLInputElement>
}

export default (props: Props) => {
  const ref = useRef(null);

  return (
    <Controller
      {...props.controllerProps}
      render={({ field }) => {
        return (
          <InputGroup
            {...field}
            disabled={props.disabled}
            value={field.value ?? ''}
            {...props.inputProps}
            ref={ref}
            autoFocus={props.autoFocus}
            inputRef={props.inputRef}
          />
        );
      }}
    />
  );
};
