import { H3, Intent, Button, DialogBody, Dialog, DialogFooter, InputGroup, Overlay, Spinner } from '@blueprintjs/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import _, { cloneDeep, find, get } from 'lodash';

import { useAlert } from 'components/Alert';
import Table, { BulkRowActions, ParamsChangeFn, RowActions } from 'components/Table';
import {
  useSimWorksheetSummaryLazyQuery,
  useBulkDeleteSimWorksheetsMutation,
  useCloneSimWorksheetMutation,
  useDeleteSimWorksheetMutation,
  useCreateSimWorksheetMutation,
} from 'graphql/generated/graphql';
import { FilterType, GQLSimWorksheet } from 'types';
import AppToaster from 'helpers/toaster';
import styles from './index.module.css';
import AddSimWorksheetDialog from './addSimWorksheetDialog';
import { SimWorksheetToSimWorksheetInput } from 'helpers/converter';
import Select from 'components/Select';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { organizationSelectItems, teamSelectItems, seriesItems } from '../../constants';
import { selectSimWorksheetSummaryView, tableViewSlice } from 'reducers/tableView';

const columnHelper = createColumnHelper<GQLSimWorksheet>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('organization_name', {
    header: 'Organization',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('team_name', {
    header: 'Team',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('series', {
    header: 'Series',
    cell: info => {
      const getSeries = find(seriesItems, series => series.value === info.getValue());
      if (!getSeries) return '';
      return getSeries.label;
    },
    meta: {
      filter: {
        type: FilterType.SELECT,
        selectItems: seriesItems,
        multiSelect: true,
      },
    },
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('year', {
    header: 'Year',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('track', {
    header: 'Track',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('event', {
    header: 'Event',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('session', {
    header: 'Session',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('description', {
    header: 'Description',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    enableSorting: true,
  }),
  columnHelper.accessor('owner', {
    header: 'Owner',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('created_at', {
    header: 'Created',
    cell: info => {
      const value = info.getValue() as string;
      return format(new Date(value), 'MM/dd/yy HH:mm:ss');
    },
  }),
  columnHelper.accessor('updated_at', {
    header: 'Modified',
    cell: info => {
      const value = info.getValue() as string;
      return format(new Date(value), 'MM/dd/yy HH:mm:ss');
    },
  }),
] as ColumnDef<GQLSimWorksheet>[];

export default () => {
  useDocumentTitle('Apex Setup - Worksheets');
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const cloneName = useRef<HTMLInputElement>(null);
  const { tableFilters, tableSorting } = useSelector(selectSimWorksheetSummaryView);
  const [isCloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [isAddSimWorksheetOpen, setAddSimWorksheetOpen] = useState(false);
  const [currentWorksheet, setCurrentWorksheet] = useState<GQLSimWorksheet>();
  const [tableData, setTableData] = useState<GQLSimWorksheet[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [getSimWorksheets, { data, refetch, loading: isLoading }] = useSimWorksheetSummaryLazyQuery();
  const [deleteWorksheet] = useDeleteSimWorksheetMutation();
  const [cloneWorksheet] = useCloneSimWorksheetMutation();
  const [bulkDeleteSimWorksheets] = useBulkDeleteSimWorksheetsMutation();
  const [createSimWorksheet] = useCreateSimWorksheetMutation();
  const alert = useAlert();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  useEffect(() => {
    if (data) {
      setTableData(data.simWorksheets.rows as GQLSimWorksheet[]);
      setIsInitialLoad(false);
    }
  }, [data]);

  const bulkRowActions: BulkRowActions<GQLSimWorksheet> = [{
    intent: Intent.DANGER,
    label: 'Delete',
    value: rows => {
      const content = (
        <>
          <p>Delete these?</p>
          <ul>
            {rows.map(r => <li>{r.original.name}</li>)}
          </ul>
        </>
      );
      alert.showAlert(content, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        bulkDeleteSimWorksheets({
          variables: { ids: rows.map(r => r.original.id) },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Sim worksheet(s) successfully deleted',
            });
            rows.forEach(r => r.toggleSelected());
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting sim worksheet(s): ${e.message}`,
            });
          },
        });
      });
    },
  }];
  const rowActions: RowActions<GQLSimWorksheet> = [{
    label: 'Edit',
    value: row => {
      navigate(`/sim-worksheets/${row.original.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCurrentWorksheet(row.original);
      setCloneDialogOpen(true);
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      alert.showAlert(`Delete "${row.original.name}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteWorksheet({
          variables: { id: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Sim worksheet successfully deleted',
            });
            if (row.getIsSelected()) row.toggleSelected();
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting sim worksheet: ${e.message}`,
            });
          },
        });
      });
    },
  }];

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    dispatch(tableViewSlice.actions.setSimWorksheetSummaryView({ filters, sorting }));

    getSimWorksheets({
      variables: {
        input: {
          filters: _.mapValues(_.keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };
  const debouncedOnTableParamsChange = _.debounce(onTableParamsChange, 200);

  const onOkCloneSimWorksheet = () => {
    setCloneDialogOpen(false);
    if (!currentWorksheet) return;
    cloneWorksheet({
      variables: {
        id: currentWorksheet.id,
        name: cloneName.current?.value || `${currentWorksheet.name}@${Date.now()}`,
        orgName: currentWorksheet.organization_name,
        orgId: currentWorksheet.organization_id,
        teamId: currentWorksheet.team_id,
        teamName: currentWorksheet.team_name,
        series: currentWorksheet.series,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned sim worksheet',
        });
        refetch();
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone sim worksheet: ${e.message}`,
        });
      },
    });
  };

  const onOkAddSimWorksheet = (input: GQLSimWorksheet) => {
    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);

    createSimWorksheet({
      variables: {
        input: {
          ...SimWorksheetToSimWorksheetInput(input),
          organization_id: get(findOrg, 'id', null),
          team_id: get(findTeam, 'id', null),
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully created sim worksheet',
        });
        refetch();
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to create sim worksheet: ${e.message}`,
        });
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectItem = (item: any, name: string) => {
    const clone = cloneDeep(currentWorksheet);

    if (clone) {
      if (name === 'series') {
        clone.series = item.value;
      }

      if (name === 'org') {
        clone.organization_name = item.value;
        clone.organization_id = item.id;

        // Reset team selections if org changes.
        const findTeam = find(teams, team => team.name === currentWorksheet?.team_name);
        if (findTeam && findTeam.organization.name !== item.value) {
          clone.team_name = null;
          clone.team_id = null;
        }
      }

      if (name === 'team') {
        clone.team_name = item.value;
        clone.team_id = item.id;
      }

      setCurrentWorksheet(clone);
    }
  };

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Sim Worksheets</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setAddSimWorksheetOpen(true)}
          text="Create Sim Worksheet"
        />
      </div>
      <Table
        bulkRowActions={bulkRowActions}
        columns={columns}
        data={tableData}
        enableHiding
        enablePagination
        enableRowSelection
        id="sim_worksheet_summary"
        manualFiltering
        manualPagination
        manualSorting
        initialColumnFilters={tableFilters}
        initialSorting={tableSorting}
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        persistColumnVisibility
        rowActions={rowActions}
        totalRowCount={data?.simWorksheets.totalCount}
      />

      {/* -------- modals --------- */}
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneDialogOpen}
        onClose={() => setCloneDialogOpen(false)}
        title={`Cloning from "${currentWorksheet?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <div>
            <div style={{ paddingBottom: '5px' }}>Organization</div>
            <Select
              initialItem={{ label: currentWorksheet?.organization_name || 'None', value: currentWorksheet?.organization_name || null }}
              items={organizationSelectItems(organizations)}
              noSelectionText="Organization"
              onChange={item => setSelectItem(item, 'org')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Team</div>
            <Select
              initialItem={{ label: currentWorksheet?.team_name || 'None', value: currentWorksheet?.team_name || null }}
              items={teamSelectItems(teams, currentWorksheet?.organization_name)}
              noSelectionText="Team"
              onChange={item => setSelectItem(item, 'team')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Series</div>
            <Select
              initialItem={seriesItems.find(i => currentWorksheet?.series === i.value)}
              items={seriesItems}
              noSelectionText="Series"
              onChange={item => setSelectItem(item, 'series')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Name</div>
            <InputGroup
              placeholder="Enter new worksheet name"
              inputRef={cloneName}
              defaultValue={`${currentWorksheet?.name} CLONE`}
            />
          </div>
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => onOkCloneSimWorksheet()}
            />
          )}
        />
      </Dialog>

      <AddSimWorksheetDialog
        isOpen={isAddSimWorksheetOpen}
        onClose={() => setAddSimWorksheetOpen(false)}
        onOk={onOkAddSimWorksheet}
      />

      <Overlay
        isOpen={isLoading && isInitialLoad}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </>
  );
};
