import React, { useRef, useCallback } from 'react';
import { FormGroup, Intent } from '@blueprintjs/core';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import IconTooltip from 'components/IconTooltip';
import RHFTextInput from 'components/RHFInputs/TextInput';
import { ExpressionInputProps } from '../../types';
import useExpressionDropdown from '../../hooks/useExpressionDropdown';
import { validateExpressionSyntax } from '../../helpers/expressionsValidation';
import styles from './index.module.css';
import getFilteredFields from './filteredFields';

const ExpressionInput: React.FC<ExpressionInputProps> = ({
  fields,
  darkMode,
  onExpressionChange,
  fieldType = 'setup',
}) => {
  const { control, setValue, getValues, formState: { errors } } = useFormContext();
  const expressionInputRef = useRef<HTMLInputElement>(null);
  const { state: dropdownState, updateState, reset: resetDropdown } = useExpressionDropdown();

  const isTokenModification = useCallback((prevValue: string, newValue: string, position: number): boolean => {
    const fieldRegex = /\{[^}]+\}/g;
    const matches = Array.from(prevValue.matchAll(fieldRegex));

    return matches.some(match => {
      if (typeof match.index !== 'number') {
        return false;
      }
      const tokenStart = match.index;
      const tokenEnd = tokenStart + match[0].length;
      return position > tokenStart && position <= tokenEnd;
    });
  }, []);

  const handleTokenModification = useCallback((prevValue: string, newValue: string, position: number) => {
    if (newValue.length < prevValue.length) {
      const fieldRegex = /\{[^}]+\}/g;
      const matches = Array.from(prevValue.matchAll(fieldRegex));

      const matchToModify = matches.find(match => {
        if (typeof match.index !== 'number') {
          return false;
        }
        const tokenStart = match.index;
        const tokenEnd = tokenStart + match[0].length;
        return position > tokenStart && position <= tokenEnd;
      });

      if (matchToModify && typeof matchToModify.index === 'number') {
        const tokenStart = matchToModify.index;
        const tokenEnd = tokenStart + matchToModify[0].length;
        const newExpressionValue = prevValue.slice(0, tokenStart) + prevValue.slice(tokenEnd);
        setValue('expression', newExpressionValue);
        onExpressionChange?.(newExpressionValue);

        setTimeout(() => {
          if (expressionInputRef.current) {
            expressionInputRef.current.setSelectionRange(tokenStart, tokenStart);
          }
        }, 0);
      }
    }
  }, [setValue, onExpressionChange]);

  const handleExpressionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, selectionStart } = event.target;
    const prevValue = getValues('expression') || '';
    const position = selectionStart || 0;

    const isModifyingToken = isTokenModification(prevValue, value, position);

    if (isModifyingToken && value.length < prevValue.length) {
      handleTokenModification(prevValue, value, position);
    } else {
      setValue('expression', value);
      onExpressionChange?.(value);

      if (value[position - 1] === '{') {
        updateState({
          isVisible: true,
          filterText: '',
          cursorPosition: position,
        });
      } else if (dropdownState.isVisible) {
        const lastOpenBrace = value.lastIndexOf('{', position - 1);
        if (lastOpenBrace !== -1) {
          updateState({
            filterText: value.slice(lastOpenBrace + 1, position),
          });
        } else {
          updateState({ isVisible: false });
        }
      }
    }
  }, [
    getValues,
    setValue,
    onExpressionChange,
    isTokenModification,
    handleTokenModification,
    dropdownState.isVisible,
    updateState,
  ]);

  const handleFieldSelect = useCallback((fieldName: string) => {
    const currentValue = getValues('expression') || '';
    const lastOpenBrace = currentValue.lastIndexOf('{', dropdownState.cursorPosition - 1);
    const prefixPart = currentValue.slice(0, lastOpenBrace + 1);
    const newValue = `${prefixPart}${fieldName}}`;

    setValue('expression', newValue);
    onExpressionChange?.(newValue);
    resetDropdown();

    const newCursorPosition = lastOpenBrace + fieldName.length + 2;
    setTimeout(() => {
      if (expressionInputRef.current) {
        expressionInputRef.current.focus();
        expressionInputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      }
    }, 0);
  }, [dropdownState.cursorPosition, getValues, setValue, onExpressionChange, resetDropdown]);

  const filteredOptions = getFilteredFields(
    fields,
    dropdownState.filterText,
    getValues('expression') || '',
    fieldType
  );

  const tooltipContent = fieldType === 'setup'
    ? `Create mathematical expressions using setup fields.
       Reference fields using curly braces, e.g: {field_1} + {field_2}
       Supported operators: +, -, *, /, **, (, )`
    : `Create mathematical expressions using run fields.
       Reference fields using curly braces, e.g: {field_1} + {field_2}
       Supported operators: +, -, *, /, **, (, )`;

  return (
    <div className={classNames(styles.container, { [styles.dark]: darkMode })}>
      <div className={styles.expressionContainer}>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="expression" />}
          label="Expression"
          labelInfo={(
            <>
              <IconTooltip content={tooltipContent} />
              <span> (required)</span>
            </>
          )}
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: 'expression',
              rules: {
                required: 'Expression is required',
                pattern: {
                  value: /^[{}\w\s+\-*/().]+$/,
                  message: 'Expression contains invalid characters',
                },
                validate: {
                  syntax: value => {
                    try {
                      validateExpressionSyntax(value);
                      return true;
                    } catch (error) {
                      return error instanceof Error ? error.message : 'Invalid expression';
                    }
                  },
                },
              },
            }}
            inputProps={{
              intent: errors.expression ? Intent.DANGER : Intent.NONE,
              onChange: handleExpressionChange,
              placeholder: 'Example: ({field_1} + {field_2}) / 2',
            }}
            inputRef={expressionInputRef}
          />
          {dropdownState.isVisible && filteredOptions.length > 0 && (
            <div className={styles.setupFieldDropdown}>
              {filteredOptions.map(({ field }) => (
                <div
                  key={field.id}
                  className={styles.setupFieldOption}
                  onClick={() => handleFieldSelect(field.name)}
                >
                  <span className={styles.fieldName}>{field.name}</span>
                  <span className={styles.fieldLabel}>{field.label}</span>
                </div>
              ))}
            </div>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

export default React.memo(ExpressionInput);
