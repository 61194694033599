import React from 'react';
import { Tooltip2 } from '@blueprintjs/popover2';
import { Link } from 'react-router-dom';
import { baseName } from '../../config';

interface SetupCheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  color?: string;
  key?: string;
  tooltip?: string;
  branchId?: number;
  showExtended?: boolean;
  setupName?: string;
  branchName?: string;
  disabled?: boolean;
}

const SetupCheckbox: React.FC<SetupCheckboxProps> = ({
  checked = false,
  onChange,
  color = '#000000',
  key,
  tooltip = '',
  branchId,
  showExtended = false,
  setupName = '',
  branchName = '',
  disabled = false,
}) => {
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked || false;
    if (onChange) {
      onChange(isChecked);
    }
  };

  const basePath = baseName === '/'
    ? `${window.location.origin}/setup`
    : `${window.location.origin}${baseName}/setup`;

  const labelNode = (
    <Link to={`${basePath}/${branchId}`} target="_blank">
      {showExtended ? (
        <span style={{ color, marginLeft: '3px' }}>{setupName} - {branchName}</span>
      ) : null}
    </Link>
  );

  const linkStyle = {
    color,
    verticalAlign: 'top',
    textOverflow: 'ellipsis',
  };

  const checkboxStyle = {
    backgroundColor: 'transparent',
    width: '18px',
    height: '18px',
    boxShadow: 'none',
    outline: 'none',
    accentColor: color,
    opacity: 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label key={key}>
      <Tooltip2 content={tooltip}>
        <div>
          <input
            type="checkbox"
            onChange={handleCheck}
            checked={checked}
            key={key}
            style={checkboxStyle}
          />
          <span style={linkStyle}>{labelNode}</span>
        </div>
      </Tooltip2>
    </label>
  );
};

export default SetupCheckbox;
