import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Sim,
  useSimByIdQuery,
} from 'graphql/generated/graphql';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Kinematics from './Kinematics';
import Setup from './Setup';
import RideModel from './RideModel';
import Simulator from './Simulator';
import EightPost from './EightPost';
import Elap from './Elap';
import PitStop from './PitStop';

export default () => {
  const params = useParams();
  const simId = Number(params.simId);

  const [sim, setSim] = useState<Sim>();

  useDocumentTitle(
    sim && sim.name ? `Apex Setup - ${sim.name}` : 'Apex Setup'
  );

  useSimByIdQuery({
    variables: { id: simId },
    skip: !simId,
    onCompleted: data => {
      if (data.sim) {
        setSim(data.sim);
      }
    },
  });

  switch (sim?.type) {
    case 'KINEMATICS': return <Kinematics sim={sim} />;
    case 'SETUP': return <Setup sim={sim} />;
    case 'RIDEMODEL': return <RideModel sim={sim} />;
    case 'SIMULATOR': return <Simulator sim={sim} />;
    case 'EIGHTPOST': return <EightPost sim={sim} />;
    case 'TRD_ELAP': return <Elap sim={sim} />;
    case 'AVL_ELAP': return <Elap sim={sim} />;
    case 'PITSTOP': return <PitStop sim={sim} />;
    default: return null;
  }
};
