import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useLocation,
  useParams,
  useSearchParams,
  useRouteLoaderData,
  unstable_useBlocker,      // eslint-disable-line camelcase
  unstable_BlockerFunction, // eslint-disable-line camelcase
} from 'react-router-dom';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  Card,
  Button,
  Elevation,
  Checkbox,
  Callout,
  Intent,
  Divider,
  Overlay,
  Spinner,
} from '@blueprintjs/core';
import _, { startCase, isEqual, get, isEmpty, join } from 'lodash';

import Select from 'components/Select';
import SetupCard, { TemplateMeta } from 'components/SetupCard';
import { baseName } from 'config';
import {
  Setup,
  SetupBranch,
  SetupField,
  useSetupBranchesByIdsQuery,
  useSetupFieldsQuery,
  useSUITByIdQuery,
  useSUITsQuery,
  useCommitSetupMutation,
  useSUITFormPartsLazyQuery,
  Part,
  SetupByBranchIdDocument,
  SetupByIdDocument,
  SetupBranchesByRootIdDocument,
} from 'graphql/generated/graphql';
import { generateTemplateMeta, getContainerPathKeys, suitPathToKey } from 'helpers/suit';
import { selectActiveSUITId, SUITSlice } from 'reducers/suit';
import { selectSetupCompareState } from 'reducers/setupCompare';
import { selectSetupColorsState } from '../../reducers/setupColors';
import {
  SetupUITemplate,
  SetupUITemplateContainerItem,
  SetupUITemplateItem,
  SetupUITemplateItemType,
  SelectItem,
  SetupSelection,
  SUITNoTemplate,
  SetupUITemplateFieldItem,
} from 'types';
import AppToaster from 'helpers/toaster';
import { SetupToSetupInput } from 'helpers/converter';
import { hasPermission } from '../../helpers/permissions';
import { PermissionName, DEFAULT_COLORS } from '../../constants';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

export enum HighlightOption {
  DIFFERENCES,
  EQUALITIES,
  NONE,
}
const HIGHLIGHT_OPTIONS = [
  { value: HighlightOption.NONE, label: 'No Highlighting' },
  { value: HighlightOption.DIFFERENCES, label: 'Highlight Differences' },
  { value: HighlightOption.EQUALITIES, label: 'Highlight Equalities' },
];

export enum ShowOption {
  ALL,
  DIFFERENCES,
}
const SHOW_OPTIONS = [
  { value: ShowOption.ALL, label: 'Show All' },
  { value: ShowOption.DIFFERENCES, label: 'Show Differences' },
];

interface SectionSelections {
  [key: string]: boolean;
}

interface SetupTemplateMeta {
  [key: string]: TemplateMeta;
}

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const branchId = Number(params.branchId);
  const compareIds = searchParams.get('ids');
  const [branchIds, setBranchIds] = useState<number []>([branchId, ...compareIds?.split(',').map(Number) ?? []]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { permissions } } = useRouteLoaderData('root') as any;

  const activeSUITId = useSelector(selectActiveSUITId);
  const activeSetupColorsState = useSelector(selectSetupColorsState);

  const [baselineSetup, setBaselineSetup] = useState<SetupSelection>();
  const [cleanBaselineSetup, setCleanBaselineSetup] = useState<SetupSelection>();
  const [isDirty, setIsDirty] = useState(false);
  const [compareSetups, setCompareSetups] = useState<SetupSelection[]>([]);
  const [setupFields, setSetupFields] = useState<SetupField[]>([]);
  const [selectedSetups, setSelectedSetups] = useState<SetupSelection[]>([]);
  const [selectedSections, setSelectedSections] = useState<SectionSelections>({});
  const [highlightOption, setHighlightOption] = useState(HIGHLIGHT_OPTIONS[1]);
  const [showOption, setShowOption] = useState(SHOW_OPTIONS[0]);
  const [activeSUITTemplate, setActiveSUIT] = useState<SetupUITemplate>();
  const [setupTemplateMeta, setSetupTemplateMeta] = useState<SetupTemplateMeta>();
  const [suitItems, setSUITItems] = useState<SelectItem<SUITNoTemplate>[]>([]);
  const [parts, setParts] = useState<Part[]>([]);
  const storeSetups = useSelector(selectSetupCompareState);

  useDocumentTitle(
    cleanBaselineSetup && cleanBaselineSetup.setup && cleanBaselineSetup.setup.name ? `Apex Setup - ${cleanBaselineSetup.setup.name}` : 'Apex Setup'
  );

  useSetupFieldsQuery({
    onCompleted: data => setSetupFields(data.setupFields.rows as SetupField[]),
  });
  useSUITsQuery({
    onCompleted: data => {
      const items: SelectItem<SUITNoTemplate>[] = data.suits.rows.map(s => ({ label: s.name, value: s }));
      setSUITItems(items);
    },
  });
  useSetupBranchesByIdsQuery({
    variables: { branchIds },
    onCompleted: data => {
      const branchHeads = data.setupBranches.map(b => ({
        branch: {
          id: b.id,
          name: b.name,
        },
        setup: { ...b.head },
      }));
      const [baselineBranch, ...compareBranches] = branchHeads.reduce((acc, b) => {
        if (b.branch.id === branchId) acc.unshift(b);
        else acc.push(b);
        return acc;
      }, [] as SetupSelection[]);
      setBaselineSetup(baselineBranch);
      setCleanBaselineSetup(baselineBranch);
      setCompareSetups(compareBranches);
    },
    fetchPolicy: 'network-only',
  });
  const { loading: suitLoading } = useSUITByIdQuery({
    variables: { id: activeSUITId || -1 },
    skip: !activeSUITId,
    onCompleted: data => {
      if (data.suit) setActiveSUIT(data.suit.template);
    },
  });

  const [commitSetup] = useCommitSetupMutation();

  const [getParts, { loading: partsLoading }] = useSUITFormPartsLazyQuery({
    onCompleted: data => setParts(data.parts.rows as Part[]),
  });

  useEffect(() => {
    const baselineSetupData = get(baselineSetup, 'setup.data');
    const cleanBaselineSetupData = get(cleanBaselineSetup, 'setup.data');

    if (!baselineSetupData || !cleanBaselineSetupData) return;

    const isDirtyCheck = !isEqual(baselineSetupData, cleanBaselineSetupData);
    const prevIsDirty = isDirty;
    setIsDirty(isDirtyCheck);
    if (isDirtyCheck !== prevIsDirty) {
      if (isDirtyCheck) addWarningListener();
      else removeWarningListener();
    }
  }, [baselineSetup, cleanBaselineSetup]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: unstable_BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  unstable_useBlocker(blockerFunc);

  useEffect(() => {
    if (activeSUITTemplate) {
      const partConfigIds: number[] = [];
      const handleSetupField = (field: SetupUITemplateFieldItem) => {
        const setupField = setupFields.find(sf => sf.name === field.setup_field);
        if (setupField?.part_config) partConfigIds.push(setupField.part_config.id);
      };

      const processTemplateItem = (item: SetupUITemplateItem) => {
        if (item.type === SetupUITemplateItemType.CONTAINER) {
          item.items.forEach(processTemplateItem);
        } else {
          handleSetupField(item);
        }
      };
      activeSUITTemplate.items.forEach(processTemplateItem);

      getParts({
        variables: {
          input: {
            filters: {
              part_config: partConfigIds,
            },
          },
        },
      });
    }
  }, [activeSUITTemplate]);

  const activeSUITSections = useMemo(() => {
    if (!activeSUITTemplate) return [];
    return _.compact(activeSUITTemplate.items.map(i => getContainerPathKeys([i]))).flat();
  }, [activeSUITTemplate]);

  const allSectionsSelected = useMemo(() => {
    return !_.toPairs(selectedSections).some(([, selected]) => !selected);
  }, [selectedSections]);

  const someSectionsSelected = useMemo(() => {
    return _.toPairs(selectedSections).some(([, selected]) => selected);
  }, [selectedSections]);

  const selectAllSections = (select: boolean) => {
    const newSelected = _.cloneDeep(selectedSections);
    _.each(activeSUITSections, section => { newSelected[section] = select; });
    setSelectedSections(newSelected);
  };

  // When (almost) any relevant data changes, regenerates the meta using the
  // configured settings (highlight, show option, etc).
  useEffect(() => {
    if (activeSUITTemplate && baselineSetup) {
      // Starts by generating a template meta where all containers and fields
      // are visible
      const meta: TemplateMeta = {
        containers: {},
        fields: {},
      };
      activeSUITTemplate.items.map(i => generateTemplateMeta([i], meta, setupFields));

      // Loops the container keys and uses `selectedSections` to determine
      // which to mark as non-visible.
      //
      // This applies to all setups, so this happens before cloning off for each
      // selected setup
      const containerKeys = Object.keys(meta.containers);
      for (let i = 0; i < containerKeys.length; i++) {
        const containerKey = containerKeys[i];
        if (!selectedSections[containerKey]) {
          meta.containers[containerKey].visible = false;
        }
      }

      // Loops through the fields for each setup and hides equal fields
      //
      // This applies to all setups, so this happens before cloning off for each
      // selected setup.
      const fieldPaths = Object.keys(meta.fields);
      if (showOption.value === ShowOption.DIFFERENCES) {
        for (let i = 0; i < fieldPaths.length; i++) {
          const fieldPath = fieldPaths[i];
          const baselineValue = _.get(baselineSetup.setup.data, fieldPath);

          for (let j = 0; j < selectedSetups.length; j++) {
            const compareSetup = selectedSetups[j];
            const compareValue = _.get(compareSetup.setup.data, fieldPath);
            if (baselineValue !== compareValue) {
              meta.fields[fieldPath].visible = true;
              break;
            }
            meta.fields[fieldPath].visible = false;
          }
        }
      }

      // Loops [baselineSetup, ...selectedSetups] and sets the highlighting
      // based on the option and comparative values
      const setupMetas: SetupTemplateMeta = {};
      const allSetups = [baselineSetup, ...selectedSetups];
      for (let i = 0; i < allSetups.length; i++) {
        const compareSetup = allSetups[i];
        if (!baselineSetup || !compareSetup) continue;

        const isBaseline = i === 0;
        const setupMeta = _.cloneDeep(meta);

        for (let j = 0; j < fieldPaths.length; j++) {
          const fieldPath = fieldPaths[j];

          const baselineValue = _.get(baselineSetup.setup.data, fieldPath);
          const compareValue = _.get(compareSetup.setup.data, fieldPath);

          if (!isBaseline && ((compareValue !== baselineValue && highlightOption.value === HighlightOption.DIFFERENCES)
            || (compareValue === baselineValue && highlightOption.value === HighlightOption.EQUALITIES))) {
            setupMeta.fields[fieldPath].inputClassName = styles.highlightedFieldValue;
          }
        }

        setupMetas[compareSetup.branch.id.toString()] = setupMeta;
      }

      setSetupTemplateMeta(setupMetas);
    }
  }, [baselineSetup, selectedSetups, activeSUITTemplate, selectedSections, highlightOption, showOption]);

  useEffect(() => {
    setSelectedSetups(compareSetups);
  }, [compareSetups]);

  // Enables all sections by default when a template changes
  useEffect(() => {
    selectAllSections(true);
  }, [activeSUITTemplate, activeSUITSections]);

  // Appends selected setup IDs to the URL query params
  useEffect(() => {
    if (storeSetups && storeSetups[branchId]) {
      let newCompareIds: number [] = [];
      let compareUrl = baseName === '/'
        ? `${window.location.origin}${location.pathname}`
        : `${window.location.origin}${baseName}${location.pathname}`;
      if (storeSetups[branchId].length > 0) {
        newCompareIds = storeSetups[branchId];
        if (!isEmpty(newCompareIds)) compareUrl += `?ids=${join(newCompareIds)}`;
        window.history.replaceState(null, '', compareUrl);
      }
      setBranchIds([branchId, ...newCompareIds]);
    }
  }, [storeSetups]);

  const handleSelectSection = (section: string) => {
    const newSelections = _.cloneDeep(selectedSections);
    const existingSelection = _.get(selectedSections, section, false);
    newSelections[section] = !existingSelection;
    setSelectedSections(newSelections);
  };

  const onContainerCollapse = (id: string | undefined, newOpenState: boolean) => {
    if (id && setupTemplateMeta) {
      const newMetas = Object.keys(setupTemplateMeta).reduce((acc, setupId) => {
        const newMeta = _.cloneDeep(setupTemplateMeta[setupId]);
        newMeta.containers[id].open = newOpenState;
        acc[setupId] = newMeta;
        return acc;
      }, {} as SetupTemplateMeta);
      setSetupTemplateMeta(newMetas);
    }
  };

  const onSUITChange = (item: SelectItem<SUITNoTemplate>) => {
    dispatch(SUITSlice.actions.setActiveSUITId(item.value.id));
  };

  const handleNodeClick = (event: React.MouseEvent<HTMLSpanElement>, key: string) => {
    event.preventDefault();

    const sectionElement = document.getElementById(key);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'auto' });
    }
  };

  const renderNavItem = (path: SetupUITemplateItem[]): ReactNode => {
    const item = path[path.length - 1];
    if (item.type === SetupUITemplateItemType.FIELD) return null;
    // Filter nav by permissions
    if (!hasPermission(PermissionName.SETUP_WRITE, permissions) && !hasPermission(PermissionName.SETUP_READ, permissions)) {
      if (item.name.toLowerCase() !== 'car') {
        if (!hasPermission(`setup_${item.name.toLowerCase()}_read`, permissions) && !hasPermission(`setup_${item.name.toLowerCase()}_write`, permissions)) return null;
      }
    }
    const depth = path.length - 1;
    const key = suitPathToKey(path as SetupUITemplateContainerItem[]);
    const checked = _.get(selectedSections, key, false);

    // Loops through the path, excluding the final (current) item, and
    // determines whether any ancestor in the current chain is checked. If so,
    // the current item checkbox is disabled
    const ancestorChecked = path.slice(0, -1).reduce((acc, node, currIndex, nodes) => {
      const ancestorPath = nodes.slice(0, currIndex + 1);
      const pathKey = suitPathToKey(ancestorPath as SetupUITemplateContainerItem[]);
      const ancestorPathChecked = _.get(selectedSections, pathKey, false);
      if (acc && !ancestorPathChecked) return false;
      return acc;
    }, true);

    const labelNode = (
      <span onClick={(event) => handleNodeClick(event, key)}>{item.label ?? startCase(item.name)}</span>
    );

    return (
      <React.Fragment key={key}>
        <Checkbox
          className={styles.sectionSelectCheckbox}
          checked={checked}
          disabled={path.length > 1 && !ancestorChecked}
          labelElement={labelNode}
          onChange={() => handleSelectSection(key)}
          style={{ marginLeft: depth * 15 }}
        />
        {item.items.map(i => renderNavItem([...path, i]))}
      </React.Fragment>
    );
  };

  const onSetupChange = (updatedSetup: Setup) => {
    if (baselineSetup && baselineSetup.branch) {
      const updatedBaseline: SetupSelection = {
        branch: baselineSetup?.branch,
        setup: updatedSetup,
      };
      setBaselineSetup(updatedBaseline);
    }
  };

  const onSave = async () => {
    if (!baselineSetup || !baselineSetup.branch) return;

    await commitSetup({
      variables: {
        branchId: baselineSetup.branch.id,
        setup: SetupToSetupInput(baselineSetup.setup),
      },
      onCompleted: () => {
        setCleanBaselineSetup(baselineSetup);
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully saved setup',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error saving setup: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }, { variables }) => {
        if (mutationData?.commitedSetup) {
          cache.writeQuery({
            query: SetupByBranchIdDocument,
            variables: { branchId: variables?.branchId },
            data: {
              setup: { ...mutationData.commitedSetup },
            },
          });

          cache.writeQuery({
            query: SetupByIdDocument,
            variables: { id: mutationData.commitedSetup.id },
            data: {
              setup: mutationData.commitedSetup,
            },
          });

          if (baselineSetup.setup.root?.id) {
            cache.updateQuery({
              query: SetupBranchesByRootIdDocument,
              variables: { rootId: baselineSetup.setup.root.id },
            }, queryData => {
              if (!queryData) return undefined;

              return {
                branches: queryData.branches.map((b: SetupBranch) => {
                  if (b.id !== baselineSetup.branch.id) return b;
                  return {
                    ...b,
                    head: {
                      ...b.head,
                      id: mutationData.commitedSetup?.id,
                    },
                  };
                }),
              };
            });
          }
        }
      },
    });
  };

  const renderCompareOptions = () => {
    return (
      <Card interactive={false} elevation={Elevation.ZERO} className={styles.optionsContainer}>
        <Button
          className={styles.saveButton}
          icon="git-commit"
          intent={Intent.PRIMARY}
          onClick={onSave}
          text="Save"
          fill
          disabled={!activeSUITTemplate || !isDirty}
        />
        <div className={styles.suitSelector}>
          <span className={styles.label}>SUIT</span>
          <Select
            value={_.find(suitItems, o => (o.value.id === activeSUITId))}
            items={suitItems}
            noSelectionText="Select SUIT"
            onChange={onSUITChange}
            fill
          />
        </div>
        {activeSUITTemplate ? (
          <>
            <div className={styles.selectContainer}>
              <Checkbox
                checked={allSectionsSelected}
                indeterminate={!allSectionsSelected && someSectionsSelected}
                label="Select All"
                onChange={() => selectAllSections(!allSectionsSelected)}
              />
              <Divider className={styles.selectDivider} />
              {activeSUITTemplate.items.map(item => renderNavItem([item]))}
            </div>

            <div className={styles.marginBottom}>
              <Select
                value={highlightOption}
                items={HIGHLIGHT_OPTIONS}
                onChange={setHighlightOption}
                fill
                selectProps={{ filterable: false }}
              />
            </div>

            <div className={styles.marginBottom}>
              <Select
                value={showOption}
                items={SHOW_OPTIONS}
                onChange={setShowOption}
                fill
                selectProps={{ filterable: false }}
              />
            </div>
          </>
        ) : (
          null
        )}
      </Card>
    );
  };

  return (
    <>
      <div className={styles.container}>
        {renderCompareOptions()}
        {(!activeSUITTemplate && !suitLoading) && (
          <div className={styles.calloutContainer}>
            <Callout
              intent={Intent.WARNING}
              title="No active SUIT selected!"
            />
          </div>
        )}
        {(baselineSetup && setupTemplateMeta && activeSUITTemplate) && (
          <ScrollSync>
            <div className={styles.cardsContainer}>
              <ScrollSyncPane>
                <div className={styles.baselineSetupContainer}>
                  <SetupCard
                    isBaseline
                    onContainerCollapse={onContainerCollapse}
                    setup={baselineSetup.setup as Setup}
                    branchName={baselineSetup.branch.name}
                    setupFields={setupFields}
                    template={activeSUITTemplate}
                    templateMeta={setupTemplateMeta[baselineSetup.branch.id]}
                    onSetupChange={onSetupChange}
                    parts={parts}
                    color={activeSetupColorsState[baselineSetup.branch.id] || DEFAULT_COLORS[0]}
                  />
                </div>
              </ScrollSyncPane>
              <ScrollSyncPane>
                <div className={styles.compareSetupsContainer}>
                  {selectedSetups.map((compareSetup) => {
                    if (!setupTemplateMeta?.[compareSetup.branch.id]) return null;
                    return (
                      <SetupCard
                        key={`compare-setup-${compareSetup.branch.id}`}
                        onContainerCollapse={onContainerCollapse}
                        setup={compareSetup.setup as Setup}
                        branchName={compareSetup.branch.name}
                        setupFields={setupFields}
                        template={activeSUITTemplate}
                        templateMeta={setupTemplateMeta[compareSetup.branch.id]}
                        color={activeSetupColorsState[compareSetup.branch.id] || DEFAULT_COLORS[Math.floor(Math.random() * DEFAULT_COLORS.length)]}
                      />
                    );
                  })}
                </div>
              </ScrollSyncPane>
            </div>
          </ScrollSync>
        )}
      </div>
      <Overlay isOpen={partsLoading || suitLoading} className="bp3-overlay-scroll-container">
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </>
  );
};
