import { ReactNode, useEffect, useMemo, useState, FocusEvent } from 'react';
import { Card, InputGroup, H4, NonIdealState, Checkbox, Alignment, ControlGroup, Label } from '@blueprintjs/core';
import classNames from 'classnames';
import _, { startCase, cloneDeep, set, groupBy, includes } from 'lodash';

import Accordion from 'components/Accordion';
import PartSelector from 'components/SUITForm/PartSelector';
import {
  Setup,
  SetupFieldType,
  SetupField,
  Part,
} from 'graphql/generated/graphql';
import { suitPathToKey } from 'helpers/suit';
import getSetupFieldProps from 'helpers/setupField';
import {
  SetupUITemplate,
  SetupUITemplateContainerItem,
  SetupUITemplateFieldItem,
  SetupUITemplateItem,
  SetupUITemplateItemType,
} from 'types';

import styles from './index.module.css';
import { useRouteLoaderData } from 'react-router-dom';
import { hasPermission, getOrgIds } from '../../helpers/permissions';
import { OrganizationsList, PermissionName } from '../../constants';

export interface TemplateFieldMeta {
  inputClassName?: string;
  rightElement?: ReactNode;
  visible?: boolean;
}
export interface TemplateContainerMeta {
  open?: boolean;
  visible?: boolean;
}
export interface TemplateMeta {
  containers: {
    [path: string]: TemplateContainerMeta;
  };
  fields: {
    [path: string]: TemplateFieldMeta;
  };
}

export interface Selections {
  [path: string]: boolean;
}

export type SetupChangeHandler = (setup: Setup) => void;

interface Props {
  isBaseline?: boolean;
  isMergeTarget?: boolean;
  enableSelections?: boolean;
  onContainerCollapse?: (id: string | undefined, newOpenState: boolean) => void;
  onSelectionsChange?: (selections: Selections) => void;
  setup: Setup;
  branchName?: string;
  setupFields: SetupField[];
  template: SetupUITemplate;
  templateMeta?: TemplateMeta;
  onSetupChange?: SetupChangeHandler;
  parts?: Part[];
  color?: string,
}

export default (props: Props) => {
  const [selections, setSelections] = useState<Selections>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let { getUser: { teams, permissions, organizations } } = useRouteLoaderData('root') as any;

  // If a team setup and user is not a TRD member, get team specific permissions
  if (props.setup.team_id && !includes(getOrgIds(organizations), OrganizationsList.TRD)) {
    const findTeam = teams.find((t: { id: string; }) => t.id === props.setup.team_id);
    permissions = findTeam ? findTeam.permissions.map((p: { name: string; }) => p.name) : [];
  }

  const allFieldsSelected = useMemo(() => {
    const visibleFields = Object.keys(props.templateMeta?.fields ?? []).reduce((acc, path) => {
      const field = props.templateMeta?.fields[path];
      if (field?.visible) acc.push(path);
      return acc;
    }, [] as string[]);
    return !visibleFields.some(f => !selections[f]);
  }, [props.templateMeta, selections]);

  const someFieldsSelected = useMemo(() => {
    return Object.values(selections).includes(true) && !allFieldsSelected;
  }, [props.template, allFieldsSelected, selections]);

  const partsByConfig = useMemo(() => {
    if (!props.parts) return {};
    return groupBy(props.parts, p => p.config.name);
  }, [props.parts]);

  // Unselects fields which have been toggled to not visible
  useEffect(() => {
    const newSelections = { ...selections };
    Object.entries(selections).forEach(([path]) => {
      if (props.templateMeta?.fields[path]?.visible === false) {
        newSelections[path] = false;
      }
    });
    setSelections(newSelections);
  }, [props.templateMeta]);

  useEffect(() => {
    props.onSelectionsChange?.(selections);
  }, [selections]);

  const handleToggleSelection = (path: string) => {
    const newSelections = {
      ...selections,
      [path]: !selections[path],
    };
    setSelections(newSelections);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => e.target.select();

  const selectAllFields = (select: boolean) => {
    const visibleFields = Object.keys(props.templateMeta?.fields ?? []).reduce((acc, path) => {
      const field = props.templateMeta?.fields[path];
      if (field?.visible) acc.push(path);
      return acc;
    }, [] as string[]);

    const newSelections: Selections = {};
    visibleFields.forEach(f => { newSelections[f] = select; });
    setSelections(newSelections);
  };

  const onSetupDataChange = (fieldPath: string, value: boolean | number | string) => {
    if (!props.setup) return;

    const newSetup = cloneDeep(props.setup);
    set(newSetup, `data.${fieldPath}`, value);

    props.onSetupChange?.(newSetup);
  };

  const headerStyle = {
    color: props.color || 'white',
  };

  const renderTemplateFieldItem = (path: SetupUITemplateItem[], readOnly: boolean) => {
    const item = path[path.length - 1] as SetupUITemplateFieldItem;
    const setupFieldProps = getSetupFieldProps(item.setup_field, props.setupFields);
    return _.map(setupFieldProps, setupFieldProp => {
      const fieldMeta = props.templateMeta?.fields[setupFieldProp.path];

      // Hide expression fields when comparing setups. May change down the road.
      if (setupFieldProp.setupField.type === 'EXPRESSION') return null;

      if (fieldMeta && fieldMeta.visible === false) return null;

      let fieldValue;
      let field = (
        <InputGroup
          className={classNames(styles.fieldValue, styles.notSet, fieldMeta?.inputClassName)}
          disabled
          fill
          value="Not Set"
          inputClassName={styles.customDisabled}
        />
      );
      if (setupFieldProp.type === SetupFieldType.PART) {
        const configParts = partsByConfig[setupFieldProp.setupField.part_config!.name] ?? [];  // eslint-disable-line @typescript-eslint/no-non-null-assertion
        const partId = _.get(props.setup, `data.${setupFieldProp.path}`);

        const part = (props.isBaseline && !props.isMergeTarget)
          ? _.find(configParts, o => o.id === partId)
          : _.find(props.setup?.parts, o => o.id === partId);

        if (part) {
          fieldValue = _.get(part, 'part_number');
          field = (
            <PartSelector
              className={classNames(styles.fieldValue, fieldMeta?.inputClassName)}
              disabled={!props.isBaseline || readOnly || props.isMergeTarget}
              initialPart={part}
              parts={props.isBaseline ? configParts : []}
              onChange={partId => onSetupDataChange(setupFieldProp.path, partId)}
              buttonStyles={(!props.isBaseline || readOnly || props.isMergeTarget) ? styles.customDisabled : undefined}
            />
          );
        } else if (props.isBaseline) {
          field = (
            <PartSelector
              className={styles.fieldValue}
              initialPart={undefined}
              parts={configParts}
              disabled={readOnly || props.isMergeTarget}
              onChange={partId => onSetupDataChange(setupFieldProp.path, partId)}
              buttonStyles={(readOnly || props.isMergeTarget) ? styles.customDisabled : undefined}
            />
          );
        }
      } else if (setupFieldProp.type === SetupFieldType.BOOLEAN) {
        fieldValue = _.get(props.setup, `data.${setupFieldProp.path}`);
        const checkboxValue = fieldValue || false;
        field = (
          <Checkbox
            checked={checkboxValue}
            className={styles.fieldCompareCheckbox}
            disabled={!props.isBaseline || readOnly || props.isMergeTarget}
            onChange={() => onSetupDataChange(setupFieldProp.path, !checkboxValue)}
          />
        );
      } else {
        fieldValue = _.get(props.setup, `data.${setupFieldProp.path}`);
        field = (
          <InputGroup
            className={classNames(styles.fieldValue, fieldMeta?.inputClassName)}
            disabled={!props.isBaseline || readOnly || props.isMergeTarget}
            fill
            value={fieldValue ?? ''}
            onChange={e => onSetupDataChange(setupFieldProp.path, e.target.value)}
            onFocus={handleFocus}
            inputClassName={(!props.isBaseline || readOnly || props.isMergeTarget) ? styles.customDisabled : undefined}
          />
        );
      }

      return (
        <ControlGroup className={styles.fieldInputGroup}>
          {props.isBaseline && (
            <Label
              className={styles.fieldLabel}
              title={setupFieldProp.label}
            >
              {setupFieldProp.label}
            </Label>
          )}
          {field}
          {props.enableSelections && (
            <Checkbox
              checked={selections[setupFieldProp.path] ?? false}
              className={styles.fieldCheckbox}
              onChange={() => handleToggleSelection(setupFieldProp.path)}
            />
          )}
          {fieldMeta?.rightElement ?? null}
        </ControlGroup>
      );
    });
  };

  const renderTemplateItem = (path: SetupUITemplateItem[]) => {
    const item = path[path.length - 1];
    if (item.type === SetupUITemplateItemType.CONTAINER) {
      // Filter template by permissions
      if (!hasPermission(PermissionName.SETUP_WRITE, permissions) && !hasPermission(PermissionName.SETUP_READ, permissions)) {
        if (item.name.toLowerCase() !== 'car') {
          if (!hasPermission(`setup_${item.name.toLowerCase()}_read`, permissions) && !hasPermission(`setup_${item.name.toLowerCase()}_write`, permissions)) return null;
        }
      }
      const key = suitPathToKey(path as SetupUITemplateContainerItem[]);

      const itemMeta = props.templateMeta?.containers[key];
      if (itemMeta?.visible === false) return null;

      const innerItems = _.compact(item.items.map(innerItem => renderTemplateItem([...path, innerItem])).flat());
      if (innerItems.length === 0) return null;

      return (
        <Accordion
          className={styles.itemContainer}
          key={`${props.setup.id}-${key}`}
          id={key}
          isOpen={itemMeta?.open !== false}
          title={item.label ?? startCase(item.name)}
          willToggleCollapse={props.onContainerCollapse}
        >
          <div className={styles.sectionContainer}>
            {innerItems}
          </div>
        </Accordion>
      );
    }

    const parentContainer = path[path.length - 2] as SetupUITemplateContainerItem;

    let readOnly = false;

    // Set read only permissions
    if (hasPermission(PermissionName.SETUP_READ, permissions)) {
      readOnly = true;
    } else {
      readOnly = hasPermission(`setup_${parentContainer.name.toLowerCase()}_read`, permissions);
    }

    // Set to Read Only if the setup is owend by TRD and the user is not in the TRD org
    if (props.setup.organization_id === OrganizationsList.TRD
      && !includes(getOrgIds(organizations), OrganizationsList.TRD)) {
      readOnly = true;
    }

    return renderTemplateFieldItem(path, readOnly);
  };

  const cardClasses = classNames(styles.setupCard, {
    [styles.compareSetup]: !props.isBaseline,
  });
  const items = _.compact(props.template.items.map(i => renderTemplateItem([i])).flat());

  return (
    <div className={styles.container}>
      {props.enableSelections && (
        <Checkbox
          alignIndicator={Alignment.RIGHT}
          checked={allFieldsSelected}
          className={styles.selectAllCheckbox}
          indeterminate={!allFieldsSelected && someFieldsSelected}
          label="Select All"
          onChange={() => selectAllFields(!allFieldsSelected)}
        />
      )}
      <Card className={cardClasses}>
        <H4 style={headerStyle} className={styles.setupLabel}>{props.branchName ? `${props.setup.name} - ${props.branchName}` : props.setup.name}</H4>
        {items.length > 0 && items}
        {items.length === 0 && (
          <NonIdealState
            className={styles.nonIdealState}
            icon="search"
            title="Nothing to display"
            description="Try changing the settings in the sidebar"
          />
        )}
      </Card>
    </div>
  );
};
