import { useState, useCallback } from 'react';
import { ExpressionDropdownState } from '../types';

const initialState: ExpressionDropdownState = {
  isVisible: false,
  filterText: '',
  cursorPosition: 0,
};

const useExpressionDropdown = () => {
  const [state, setState] = useState<ExpressionDropdownState>(initialState);

  const updateState = useCallback((updates: Partial<ExpressionDropdownState>) => {
    setState(prev => ({ ...prev, ...updates }));
  }, []);

  const reset = useCallback(() => {
    setState(initialState);
  }, []);

  return {
    state,
    updateState,
    reset,
  };
};

export default useExpressionDropdown;
