import { useState, useCallback, useEffect, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  EditableText,
  Button,
  Intent,
  FormGroup,
  Checkbox,
  Alignment,
  Tree,
  TreeNodeInfo,
} from '@blueprintjs/core';
import classNames from 'classnames';
import { get, find, chain } from 'lodash';
import {
  unstable_useBlocker,      // eslint-disable-line camelcase
  unstable_BlockerFunction, // eslint-disable-line camelcase
  useRouteLoaderData,
} from 'react-router-dom';

import { RHFNumericInput, RHFSelect, RHFTextInput } from 'components/RHFInputs';
import Accordion from 'components/Accordion';
import AppToaster from 'helpers/toaster';
import {
  Sim,
  UpdateSimInput,
  useUpdateSimMutation,
  useLapTimeDataQuery,
  useSimStatesQuery,
  useDriversQuery,
  SimStates,
  LapTimeDataSegment,
  SimType,
} from 'graphql/generated/graphql';
import styles from '../index.module.css';
import { ErrorMessage } from '@hookform/error-message';
import { ElapSim, ElapSegment } from '../types';
import { PermissionName } from '../../../constants';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';

interface Props {
  sim: Sim;
}

const simModes = [
  { label: '0 - Speed Follow', value: 0 },
  { label: '1 - Speed Follow (Simple-Model Adjusted', value: 1 },
  { label: '2 - Speed Follow (Scouting-Lap Adjusted', value: 2 },
];

const nodes: TreeNodeInfo[] = [
  {
    id: 0,
    hasCaret: false,
    label: 'Config',
  },
  {
    id: 1,
    hasCaret: false,
    label: 'Sim States',
  },
  {
    id: 2,
    hasCaret: false,
    label: 'Output',
  },
];

type LapTimeDataOption = {
  label: string;
  value: number;
  segments: LapTimeDataSegment[];
  track_filename: string;
  brake_aggression: number | null | undefined;
  throttle_aggression: number | null | undefined;
  part_throttle_pct: number | null | undefined;
  saturation_target_pct: number | null | undefined;
  scouting_speed_pct: number | null | undefined;
  speed_offset: number | null | undefined;
}

export default (props: Props) => {
  const defaultElapSim: ElapSim = props.sim;
  const [lapTimeDataOptions, setLapTimeDataOptions] = useState<LapTimeDataOption[]>([]);
  const [simStateOptions, setSimStateOptions] = useState<{ label: string, value: number }[]>([]);
  const [simStates, setSimStates] = useState<SimStates[]>([]);
  const [driverOptions, setDriverOptions] = useState<{ label: string, value: number }[]>([]);
  const [trackFileName, setTrackFileName] = useState('');
  const [segments, setSegments] = useState<LapTimeDataSegment[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { permissions } } = useRouteLoaderData('root') as any;
  const form = useForm<Partial<ElapSim>>({
    defaultValues: {
      ...defaultElapSim,
    },
  });
  const { control, watch, setValue, formState: { errors, isDirty }, reset } = form;
  const overridePowerScaling = watch('data.enable_power_scaling_override');
  const overrideEnvTrafficWind = watch('data.override_env_traffic_wind');
  const simStateId = watch('sim_states_id', props.sim.sim_states_id);
  const stateOptionId = watch('state_option_id', props.sim.state_option_id);
  const lapTimeDataId = watch('lap_time_data_id', props.sim.lap_time_data_id);

  const firstSimStateUpdate = useRef(true);
  const firstLapDataUpdate = useRef(true);

  const [updateSim] = useUpdateSimMutation();

  useEffect(() => {
    if (!firstSimStateUpdate.current) {
      firstSimStateUpdate.current = false;
    } else {
      setValue('state_option_id', null, { shouldDirty: true });
    }
  }, [simStateId]);

  useEffect(() => {
    // Reset the form with default values
    reset({ ...defaultElapSim });
  }, [defaultElapSim]);

  useEffect(() => {
    if (isDirty) {
      addWarningListener();
    } else {
      removeWarningListener();
    }
  }, [isDirty]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: unstable_BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  unstable_useBlocker(blockerFunc);

  useLapTimeDataQuery({
    onCompleted: data => {
      const options = data.lapTimeDatas.map(item => ({
        label: item.desc,
        value: item.id,
        segments: item.data?.segments?.filter(segment => segment !== null) as LapTimeDataSegment[] || [],
        track_filename: item.data?.track_filename || '',
        brake_aggression: item.data?.brake_aggression,
        throttle_aggression: item.data?.throttle_aggression,
        part_throttle_pct: item.data?.part_throttle_pct,
        saturation_target_pct: item.data?.saturation_target_pct,
        scouting_speed_pct: item.data?.scouting_speed_pct,
        speed_offset: item.data?.speed_offset,
      }));
      setLapTimeDataOptions(options);

      if (lapTimeDataId) {
        const lapTimeData = data.lapTimeDatas.find(lapTimeData => lapTimeData.id === lapTimeDataId);
        if (lapTimeData && lapTimeData.data?.track_filename) setTrackFileName(lapTimeData.data.track_filename);
        if (lapTimeData?.data?.segments) setSegments(lapTimeData.data.segments.filter(segment => segment !== null) as LapTimeDataSegment[]);
      }
    },
  });

  useSimStatesQuery({
    onCompleted: data => {
      setSimStates(data.simStates);
      const stateOptions = data.simStates.map(item => ({ label: item.desc, value: item.id }));
      setSimStateOptions(stateOptions);
    },
  });

  useDriversQuery({
    onCompleted: data => {
      const driverName = props.sim.type === SimType.TRD_ELAP ? 'trd' : 'avl';

      const options = chain(data.drivers)
        .filter((d) => (d.name === driverName))
        .map((d) => ({ label: d.desc, value: d.id }))
        .value();

      setDriverOptions(options);
    },
  });

  useEffect(() => {
    const lapTimeData = lapTimeDataOptions.find(lapTimeData => lapTimeData.value === lapTimeDataId);
    if (lapTimeData) {
      setTrackFileName(lapTimeData.track_filename);
      setSegments(lapTimeData.segments);

      if (!firstLapDataUpdate.current) {
        if (props.sim.type === SimType.TRD_ELAP) {
          setValue('data.brake_aggression', lapTimeData?.brake_aggression, { shouldDirty: true });
          setValue('data.throttle_aggression', lapTimeData?.throttle_aggression, { shouldDirty: true });
          setValue('data.part_throttle_pct', lapTimeData?.part_throttle_pct, { shouldDirty: true });
          setValue('data.saturation_target_pct', lapTimeData?.saturation_target_pct, { shouldDirty: true });
          setValue('data.scouting_speed_pct', lapTimeData?.scouting_speed_pct, { shouldDirty: true });
        }

        setValue('data.speed_offset', lapTimeData?.speed_offset, { shouldDirty: true });
      }
    }

    if (firstLapDataUpdate.current) {
      firstLapDataUpdate.current = false;
    }
  }, [lapTimeDataId]);

  useEffect(() => {
    const currentSimStates = find(simStates, (o) => (o.id === simStateId));
    const selectedState = find(currentSimStates?.states, (o) => (o.id === stateOptionId));

    if (selectedState) {
      const tireData = selectedState.tire;
      const brakeData = selectedState.brake;
      const fuelData = selectedState.fuel;
      const engineData = selectedState.engine;

      setValue('data.global_mu', tireData?.global_mu, { shouldDirty: true });
      setValue('data.build_lf', tireData?.pressure_build_lf, { shouldDirty: true });
      setValue('data.build_rf', tireData?.pressure_build_rf, { shouldDirty: true });
      setValue('data.build_lr', tireData?.pressure_build_lr, { shouldDirty: true });
      setValue('data.build_rr', tireData?.pressure_build_rr, { shouldDirty: true });
      setValue('data.temps_lf', tireData?.temperature_lf, { shouldDirty: true });
      setValue('data.temps_rf', tireData?.temperature_rf, { shouldDirty: true });
      setValue('data.temps_lr', tireData?.temperature_lr, { shouldDirty: true });
      setValue('data.temps_rr', tireData?.temperature_rr, { shouldDirty: true });
      setValue('data.fuel_burn', fuelData?.burn, { shouldDirty: true });
      setValue('data.water_temp', engineData?.water_temp, { shouldDirty: true });
      setValue('data.alternator_current', engineData?.alternator_current, { shouldDirty: true });
      setValue('data.rotor_temps_lf', brakeData?.rotor_temperature_lf, { shouldDirty: true });
      setValue('data.rotor_temps_rf', brakeData?.rotor_temperature_rf, { shouldDirty: true });
      setValue('data.rotor_temps_lr', brakeData?.rotor_temperature_lr, { shouldDirty: true });
      setValue('data.rotor_temps_rr', brakeData?.rotor_temperature_rr, { shouldDirty: true });
      setValue('data.rotor_temps_filtered_lf', brakeData?.rotor_temperature_filtered_lf, { shouldDirty: true });
      setValue('data.rotor_temps_filtered_rf', brakeData?.rotor_temperature_filtered_rf, { shouldDirty: true });
      setValue('data.rotor_temps_filtered_lr', brakeData?.rotor_temperature_filtered_lr, { shouldDirty: true });
      setValue('data.rotor_temps_filtered_rr', brakeData?.rotor_temperature_filtered_rr, { shouldDirty: true });
      setValue('data.lat_peak_mu_lf', tireData?.lmuy_lf, { shouldDirty: true });
      setValue('data.lat_peak_mu_rf', tireData?.lmuy_rf, { shouldDirty: true });
      setValue('data.lat_peak_mu_lr', tireData?.lmuy_lr, { shouldDirty: true });
      setValue('data.lat_peak_mu_rr', tireData?.lmuy_rr, { shouldDirty: true });
      setValue('data.long_peak_mu_lf', tireData?.lmux_lf, { shouldDirty: true });
      setValue('data.long_peak_mu_rf', tireData?.lmux_rf, { shouldDirty: true });
      setValue('data.long_peak_mu_lr', tireData?.lmux_lr, { shouldDirty: true });
      setValue('data.long_peak_mu_rr', tireData?.lmux_rr, { shouldDirty: true });
      setValue('data.corner_stiffness_lf', tireData?.lky_lf, { shouldDirty: true });
      setValue('data.corner_stiffness_rf', tireData?.lky_rf, { shouldDirty: true });
      setValue('data.corner_stiffness_lr', tireData?.lky_lr, { shouldDirty: true });
      setValue('data.corner_stiffness_rr', tireData?.lky_rr, { shouldDirty: true });
      setValue('data.slip_stiffness_lf', tireData?.lkx_lf, { shouldDirty: true });
      setValue('data.slip_stiffness_rf', tireData?.lkx_rf, { shouldDirty: true });
      setValue('data.slip_stiffness_lr', tireData?.lkx_lr, { shouldDirty: true });
      setValue('data.slip_stiffness_rr', tireData?.lkx_rr, { shouldDirty: true });
      setValue('data.lat_fall_off_peak_lf', tireData?.lyay_lf, { shouldDirty: true });
      setValue('data.lat_fall_off_peak_rf', tireData?.lyay_rf, { shouldDirty: true });
      setValue('data.lat_fall_off_peak_lr', tireData?.lyay_lr, { shouldDirty: true });
      setValue('data.lat_fall_off_peak_rr', tireData?.lyay_rr, { shouldDirty: true });
      setValue('data.long_fall_off_peak_lf', tireData?.lyax_lf, { shouldDirty: true });
      setValue('data.long_fall_off_peak_rf', tireData?.lyax_rf, { shouldDirty: true });
      setValue('data.long_fall_off_peak_lr', tireData?.lyax_lr, { shouldDirty: true });
      setValue('data.long_fall_off_peak_rr', tireData?.lyax_rr, { shouldDirty: true });
      setValue('data.squareness_combined_lf', tireData?.squareness_combined_lf, { shouldDirty: true });
      setValue('data.squareness_combined_rf', tireData?.squareness_combined_rf, { shouldDirty: true });
      setValue('data.squareness_combined_lr', tireData?.squareness_combined_lr, { shouldDirty: true });
      setValue('data.squareness_combined_rr', tireData?.squareness_combined_rr, { shouldDirty: true });
    }
  }, [stateOptionId]);

  const onSetupNameChange = (value: string) => {
    setValue('name', value, { shouldDirty: true });
  };

  const onSubmit = (input: Partial<ElapSim>) => {
    const processSimData = (data: ElapSim['data']): ElapSim['data'] => {
      // Process and validate the data
      const processedData = { ...data };

      // Ensure all number fields are actually numbers
      Object.keys(processedData).forEach(key => {
        if (typeof processedData[key] === 'string' && !Number.isNaN(Number(processedData[key]))) {
          processedData[key] = Number(processedData[key]);
        }
      });

      return processedData;
    };

    const handleSubmit = async () => {
      setIsSaving(true);

      try {
        // eslint-disable-next-line camelcase, @typescript-eslint/no-unused-vars
        const { drive_file, sim_states, driver, lap_time_data, eight_post, ...elapSim } = input;

        const processedData = processSimData(elapSim.data);
        const processedElapSim = {
          ...elapSim,
          data: processedData,
        } as ElapSim;

        const updatedSimInput: UpdateSimInput = {
          ...processedElapSim as ElapSim,
        };

        const result = await updateSim({
          variables: {
            input: {
              ...updatedSimInput,
            },
          },
        });

        if (result.data) {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Sim successfully updated',
          });

          form.reset({ ...input });
        } else {
          throw new Error('Update failed');
        }
      } catch (error) {
        let errorMessage = 'An unknown error occurred';
        if (error instanceof Error) {
          errorMessage = error.message;
        } else if (typeof error === 'string') {
          errorMessage = error;
        }
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating Sim: ${errorMessage}`,
        });
      } finally {
        setIsSaving(false);
      }
    };

    handleSubmit();
  };

  const handleNodeClick = useCallback(
    (node: TreeNodeInfo) => {
      const sectionElement = document.getElementById(`section-${node.id}`);

      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [],
  );

  const renderQuadGridInputs = (label: string, fieldName: string) => {
    return (
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label={label}
          inline
        >
          <div className={styles.gridInputs}>
            <RHFNumericInput
              controllerProps={{
                control,
                name: `data.${fieldName}_lf`,
              }}
              inputProps={{
                fill: true,
                placeholder: 'LF',
                value: watch(`data.${fieldName}_lf`),
              }}
              useStringValue
            />
            <RHFNumericInput
              controllerProps={{
                control,
                name: `data.${fieldName}_rf`,
              }}
              inputProps={{
                fill: true,
                placeholder: 'RF',
                value: watch(`data.${fieldName}_rf`),
              }}
              useStringValue
            />
            <RHFNumericInput
              controllerProps={{
                control,
                name: `data.${fieldName}_lr`,
              }}
              inputProps={{
                fill: true,
                placeholder: 'LR',
                value: watch(`data.${fieldName}_lr`),
              }}
              useStringValue
            />
            <RHFNumericInput
              controllerProps={{
                control,
                name: `data.${fieldName}_rr`,
              }}
              inputProps={{
                fill: true,
                placeholder: 'RR',
                value: watch(`data.${fieldName}_rr`),
              }}
              useStringValue
            />
          </div>
        </FormGroup>
      </div>
    );
  };

  const renderAvlElapInputs = () => (
    <>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label="Demand Speed Offset"
          labelInfo="(mph)"
          inline
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'data.speed_offset',
            }}
            inputProps={{
              fill: true,
              className: styles.inline,
              value: watch('data.speed_offset'),
            }}
            useStringValue
          />
        </FormGroup>
      </div>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label="Performance Braking Scaling"
          labelInfo="(%)"
          inline
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'data.avl_satopt_performance_braking_scale_pct',
            }}
            inputProps={{
              fill: true,
              className: styles.inline,
              value: watch('data.avl_satopt_performance_braking_scale_pct'),
            }}
            useStringValue
          />
        </FormGroup>
      </div>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label="Saturation Target Scaling"
          labelInfo="(%)"
          inline
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'data.avl_satopt_saturation_target_scale_pct',
            }}
            inputProps={{
              fill: true,
              className: styles.inline,
              value: watch('data.avl_satopt_saturation_target_scale_pct'),
            }}
            useStringValue
          />
        </FormGroup>
      </div>
    </>
  );

  const renderTrdElapInputs = () => (
    <>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label="Brake Aggression"
          labelInfo="(%)"
          inline
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'data.brake_aggression',
            }}
            inputProps={{
              fill: true,
              className: styles.inline,
              value: watch('data.brake_aggression'),
            }}
            useStringValue
          />
        </FormGroup>
      </div>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label="Throttle Aggression"
          labelInfo="(%)"
          inline
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'data.throttle_aggression',
            }}
            inputProps={{
              fill: true,
              className: styles.inline,
              value: watch('data.throttle_aggression'),
            }}
            useStringValue
          />
        </FormGroup>
      </div>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label="Part Throttle"
          labelInfo="(%)"
          inline
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'data.part_throttle_pct',
            }}
            inputProps={{
              fill: true,
              className: styles.inline,
              value: watch('data.part_throttle_pct'),
            }}
            useStringValue
          />
        </FormGroup>
      </div>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label="Demand Speed Offset"
          labelInfo="(mph)"
          inline
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'data.speed_offset',
            }}
            inputProps={{
              fill: true,
              className: styles.inline,
              value: watch('data.speed_offset'),
            }}
            useStringValue
          />
        </FormGroup>
      </div>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label="Scouting Speed"
          labelInfo="(%)"
          inline
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'data.scouting_speed_pct',
            }}
            inputProps={{
              fill: true,
              className: styles.inline,
              value: watch('data.scouting_speed_pct'),
            }}
            useStringValue
          />
        </FormGroup>
      </div>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          label="Saturation Target"
          labelInfo="(%)"
          inline
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'data.saturation_target_pct',
            }}
            inputProps={{
              fill: true,
              className: styles.inline,
              value: watch('data.saturation_target_pct'),
            }}
            useStringValue
          />
        </FormGroup>
      </div>
      <div className={styles.inputRow}>
        <FormGroup
          className={classNames(styles.formGroup, styles.flex)}
          contentClassName={styles.simInput}
          helperText={<ErrorMessage errors={errors} name="data.sim_mode" />}
          label="Simulation Mode"
          labelInfo="(required)"
          inline
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'data.sim_mode',
              rules: { required: 'Simulation Mode is required' },
            }}
            selectProps={{
              filterable: false,
            }}
            intent={get(errors, 'data.sim_mode') && Intent.DANGER}
            items={simModes}
            fill
          />
        </FormGroup>
      </div>
    </>
  );

  return (
    <FormProvider {...form}>
      <form className={styles.mainForm} onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.titleBar}>
          <div className={styles.titleColumn}>
            <div className={styles.titleLabel}>{props.sim.type === SimType.AVL_ELAP ? 'AVL eLap' : 'TRD eLap'}</div>
            <EditableText
              className={styles.titleValue}
              defaultValue={defaultElapSim.name}
              onChange={value => onSetupNameChange(value)}
              placeholder="eLap"
            />
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.nav}>
            <Button
              className={styles.saveButton}
              intent={Intent.PRIMARY}
              type="submit"
              text="Save"
              fill
              disabled={!isDirty || isSaving}
            />
            <div className={styles.sideNavContainer}>
              <Tree
                contents={nodes}
                onNodeClick={handleNodeClick}
              />
            </div>
          </div>
          <div className={styles.simContainer}>
            <Accordion
              className={styles.itemContainer}
              id="section-0"
              initialOpen
              key="config"
              title="Config"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Driver"
                    labelInfo="(required)"
                    inline
                  >
                    <RHFSelect
                      controllerProps={{
                        control,
                        name: 'driver_id',
                        rules: { required: 'Driver is required' },
                      }}
                      intent={get(errors, 'driver_id') && Intent.DANGER}
                      items={driverOptions}
                      fill
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    helperText={<ErrorMessage errors={errors} name="lap_time_data.id" />}
                    label="Lap Data"
                    labelInfo="(required)"
                    inline
                  >
                    <RHFSelect
                      controllerProps={{
                        control,
                        name: 'lap_time_data_id',
                        rules: { required: 'Lap Data File is required' },
                      }}
                      intent={get(errors, 'lap_time_data_id') && Intent.DANGER}
                      items={lapTimeDataOptions}
                      fill
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Track"
                    inline
                  >
                    <EditableText
                      value={trackFileName}
                      disabled
                    />
                  </FormGroup>
                </div>
                {props.sim.type === SimType.AVL_ELAP ? renderAvlElapInputs() : renderTrdElapInputs()}
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.enable_power_scaling_override"
                    render={({ field }) => (
                      <Checkbox
                        labelElement={(<>Override Setup Power Scaling<span className={styles.labelInfo}>(%)</span></>)}
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <FormGroup
                    className={styles.formGroup}
                    contentClassName={styles.simInput}
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.power_scaling',
                      }}
                      inputProps={{
                        fill: true,
                        disabled: !overridePowerScaling,
                      }}
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.override_env_traffic_wind"
                    render={({ field }) => (
                      <Checkbox
                        labelElement={(<>Traffic Wind<span className={styles.labelInfo}>(mph @ deg)</span></>)}
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <FormGroup
                    className={styles.formGroup}
                    contentClassName={styles.simInput}
                    inline
                  >
                    <div className={styles.gridInputs}>
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'data.override_env_traffic_wind_speed',
                        }}
                        inputProps={{
                          fill: true,
                          disabled: !overrideEnvTrafficWind,
                        }}
                      />
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'data.override_env_traffic_wind_angle',
                        }}
                        inputProps={{
                          fill: true,
                          disabled: !overrideEnvTrafficWind,
                        }}
                      />
                    </div>
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.enable_ground_contact"
                    render={({ field }) => (
                      <Checkbox
                        label="Enable Ground Contacts"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <Accordion
                  className={styles.itemContainer}
                  initialOpen
                  key="segments"
                  title="Segments"
                  buttonProps={{
                    className: styles.accordionHeader,
                  }}
                >
                  <div className={styles.sectionContainer}>
                    <table className={styles.segmentsTable}>
                      <tr>
                        <th>Label</th>
                        <th>Begin</th>
                        <th>End</th>
                        <th>Loc</th>
                      </tr>
                      {segments.map((segment: ElapSegment) => (
                        <tr key={segment.label}>
                          <td>{segment.label}</td>
                          <td>{segment.begin}</td>
                          <td>{segment.end}</td>
                          <td>{segment.loc}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </Accordion>
              </div>
            </Accordion>
            <Accordion
              className={styles.itemContainer}
              id="section-1"
              initialOpen
              key="simStates"
              title="Sim States"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    helperText={<ErrorMessage errors={errors} name="sim_states_id" />}
                    label="Simulation States"
                    inline
                  >
                    <RHFSelect
                      controllerProps={{
                        control,
                        name: 'sim_states_id',
                      }}
                      intent={get(errors, 'sim_states_id') && Intent.DANGER}
                      items={simStateOptions}
                      fill
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    helperText={<ErrorMessage errors={errors} name="sim_state_label" />}
                    label="State Option"
                    labelInfo="(required)"
                    inline
                  >
                    <RHFSelect
                      controllerProps={{
                        control,
                        name: 'state_option_id',
                        rules: { required: 'State Option is required' },
                      }}
                      intent={get(errors, 'state_option_id') && Intent.DANGER}
                      items={simStates.find(state => state.id === simStateId)?.states.map(item => ({ label: item.label, value: item.id })) ?? []}
                      fill
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Global Mu"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.global_mu',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: watch('data.global_mu'),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                {renderQuadGridInputs('Build', 'build')}
                {renderQuadGridInputs('Temps', 'temps')}
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Fuel Burn"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.fuel_burn',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: watch('data.fuel_burn'),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Water Temp"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.water_temp',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: watch('data.water_temp'),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Alternator Current"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.alternator_current',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: watch('data.alternator_current'),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                {renderQuadGridInputs('Rotor Temps', 'rotor_temps')}
                {renderQuadGridInputs('Rotor Temps Filtered', 'rotor_temps_filtered')}
                {renderQuadGridInputs('Lat Peak Mu', 'lat_peak_mu')}
                {renderQuadGridInputs('Long Peak Mu', 'long_peak_mu')}
                {renderQuadGridInputs('Corner Stiffness', 'corner_stiffness')}
                {renderQuadGridInputs('Slip Stiffness', 'slip_stiffness')}
                {renderQuadGridInputs('Lat Fall Off Peak', 'lat_fall_off_peak')}
                {renderQuadGridInputs('Long Fall Off Peak', 'long_fall_off_peak')}
                {renderQuadGridInputs('Squareness Combined', 'squareness_combined')}
              </div>
            </Accordion>
            <Accordion
              className={styles.itemContainer}
              id="section-2"
              initialOpen
              key="output"
              title="Output"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Output Directory"
                    inline
                  >
                    <RHFTextInput
                      controllerProps={{
                        control,
                        name: 'output_directory',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </FormGroup>
                </div>
                {(props.sim.type === SimType.AVL_ELAP) && (
                  <div className={styles.inputRow}>
                    <Controller
                      control={control}
                      name="export_all_laps"
                      render={({ field }) => (
                        <Checkbox
                          label="Export All Laps"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                )}
                <div className={styles.inputRow}>
                  <div className={styles.outputCheckboxContainer}>
                    <div className={styles.outputCheckboxName}>Motec</div>
                    <Controller
                      control={control}
                      name="export_motec"
                      render={({ field }) => (
                        <Checkbox
                          label="Export"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="download_motec"
                      render={({ field }) => (
                        <Checkbox
                          label="Download"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={styles.inputRow}>
                  <div className={styles.outputCheckboxContainer}>
                    <div className={styles.outputCheckboxName}>JSON</div>
                    <Controller
                      control={control}
                      name="export_json"
                      render={({ field }) => (
                        <Checkbox
                          label="Export"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="download_json"
                      render={({ field }) => (
                        <Checkbox
                          label="Download"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={styles.inputRow}>
                  <div className={styles.outputCheckboxContainer}>
                    <div className={styles.outputCheckboxName}>Metrics</div>
                    <Controller
                      control={control}
                      name="export_metrics"
                      render={({ field }) => (
                        <Checkbox
                          label="Export"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="download_metrics"
                      render={({ field }) => (
                        <Checkbox
                          label="Download"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                {
                  permissions.includes(PermissionName.SIM_SUCCESS_DEBUG) && (
                    <div className={styles.inputRow}>
                      <div className={styles.outputCheckboxContainer}>
                        <div className={styles.outputCheckboxName}>Debug</div>
                        <Controller
                          control={control}
                          name="export_debug"
                          render={({ field }) => (
                            <Checkbox
                              label="Export"
                              alignIndicator={Alignment.LEFT}
                              checked={field.value || false}
                              onChange={field.onChange}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="download_debug"
                          render={({ field }) => (
                            <Checkbox
                              label="Download"
                              alignIndicator={Alignment.LEFT}
                              checked={field.value || false}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            </Accordion>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
