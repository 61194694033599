import { PropertyType, RunFieldType, SetupFieldType, Spec, SimType, MetricCalculationMethod } from 'graphql/generated/graphql';
import _ from 'lodash';
import { SelectItem, SimDocumentCategory, GenericRunField } from 'types';

export const SETUP_FIELD_TYPES: Record<string, string> = {
  [SetupFieldType.STRING]: 'String',
  [SetupFieldType.INT]: 'Int',
  [SetupFieldType.FLOAT]: 'Float',
  [SetupFieldType.BOOLEAN]: 'Boolean',
  [SetupFieldType.PART]: 'Part',
  [SetupFieldType.COLLECTION]: 'Collection',
  [SetupFieldType.EXPRESSION]: 'Expression',
};
export const setupFieldTypeSelectItems = Object.keys(SetupFieldType).map(k => ({
  label: SETUP_FIELD_TYPES[k],
  value: SetupFieldType[k as SetupFieldType],
})) as SelectItem<SetupFieldType>[];

export const SPECS: Record<string, string> = {
  [Spec.GEN6]: 'Gen6',
  [Spec.GEN7]: 'Gen7',
  [Spec.GT3]: 'GT3',
};
export const specSelectItems = Object.keys(Spec).map(k => ({
  label: SPECS[k],
  value: Spec[k as Spec],
})) as SelectItem<Spec>[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const organizationSelectItems = (orgs: any) => {
  const items = Object.keys(orgs).map(k => ({
    label: orgs[k].name,
    value: orgs[k].name,
    id: orgs[k].id,
  }));

  return items;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const teamSelectItems = (teams: any, orgName?: string) => {
  // Filter teams by provided organization
  const findteams = _.compact(Object.keys(teams).map(k => {
    if (teams[k].organization.name === orgName) {
      return {
        label: teams[k].name,
        value: teams[k].name,
        id: teams[k].id,
      };
    }
    return undefined;
  }));

  // Teams aren't required so add option to provide no team
  findteams.push({ label: 'None', value: null, id: null });

  return findteams;
};

export const SimTypes: Record<string, string> = {
  [SimType.KINEMATICS]: 'Kinematics',
  [SimType.SETUP]: 'Setup',
  [SimType.RIDEMODEL]: 'Ride Model',
  [SimType.SIMULATOR]: 'Simulator',
  [SimType.EIGHTPOST]: 'Eight Post',
  [SimType.TRD_ELAP]: 'TRD eLap',
  [SimType.AVL_ELAP]: 'AVL eLap',
  [SimType.PITSTOP]: 'Pit Stop',
};

export const simTypeSelectItems = Object.keys(SimType).map(k => ({
  label: SimTypes[k],
  value: SimType[k as SimType],
})) as SelectItem<SimType>[];

export const RUN_FIELD_TYPES: Record<string, string> = {
  [RunFieldType.STRING]: 'String',
  [RunFieldType.TEXT]: 'Text',
  [RunFieldType.INT]: 'Int',
  [RunFieldType.FLOAT]: 'Float',
  [RunFieldType.BOOLEAN]: 'Boolean',
  [RunFieldType.EXPRESSION]: 'Expression',
};
export const runFieldTypeSelectItems = Object.keys(RunFieldType).map(k => ({
  label: RUN_FIELD_TYPES[k],
  value: RunFieldType[k as RunFieldType],
})) as SelectItem<RunFieldType>[];

export const PROPERTY_TYPES: Record<string, string> = {
  [PropertyType.BOOLEAN]: 'Boolean',
  [PropertyType.COORDINATE]: 'Coordinate',
  [PropertyType.NUMBER]: 'Number',
  [PropertyType.STRING]: 'String',
};
export const propertyTypeSelectItems = Object.keys(PropertyType).map(k => ({
  label: PROPERTY_TYPES[k],
  value: PropertyType[k as PropertyType],
})) as SelectItem<PropertyType>[];

export const RUIT_VERSION = 0.1;
export const SUIT_VERSION = 0.1;

export const PermissionName = {
  SIM_SUCCESS_DEBUG: 'sim_success_debug',
  SIM_FAILURE_DEBUG: 'sim_failure_debug',
  SETUP_WRITE: 'setup_write',
  SETUP_READ: 'setup_read',
  SETUP_BUMPSTOPS_WRITE: 'setup_bumpstops_write',
  SETUP_BUMPSTOPS_READ: 'setup_bumpstops_read',
  SETUP_SHOCKS_WRITE: 'setup_shocks_write',
  SETUP_SHOCKS_READ: 'setup_shocks_read',
  SETUP_SPRINGS_WRITE: 'setup_springs_write',
  SETUP_SPRINGS_READ: 'setup_springs_read',
  TIRES_VIEW_ACCESS: 'tires_view_access',
};

export const simDocumentCategories: SimDocumentCategory[] = [
  {
    displayName: 'Eight Post',
    name: 'eight_post',
    types: [
      {
        displayName: 'Eight Post',
        name: 'eight_post',
      },
    ],
  },
  {
    displayName: 'eLap',
    name: 'elap',
    types: [
      {
        displayName: 'Driver',
        name: 'driver',
      },
      {
        displayName: 'Lap Time Data',
        name: 'lap_time_data',
      },
    ],
  },
  {
    displayName: 'Ride Model',
    name: 'ride_model',
    types: [
      {
        displayName: 'Drive File',
        name: 'drive_file',
      },
    ],
  },
  {
    displayName: 'Misc',
    name: 'misc',
    types: [
      {
        displayName: 'Lap Wind Data',
        name: 'lap_wind_data',
      },
      {
        displayName: 'Sim States',
        name: 'sim_states',
      },
    ],
  },
];

export const yearItems: SelectItem<number>[] = [
  { label: '2024', value: 2024 },
  { label: '2025', value: 2025 },
];

export const seriesItems: SelectItem<string>[] = [
  { label: 'NCS', value: 'A' },
  { label: 'NXS', value: 'B' },
  { label: 'NCTS', value: 'C' },
];

export const presetRunFields: GenericRunField[] = [
  { label: 'Lap Time Table', name: 'lap_time_table' },
];

export const metricCalculationMethodItems: SelectItem<MetricCalculationMethod>[] = [
  { label: 'max', value: MetricCalculationMethod.MAX },
  { label: 'min', value: MetricCalculationMethod.MIN },
  { label: 'mean', value: MetricCalculationMethod.MEAN },
  { label: 'stddev', value: MetricCalculationMethod.STDDEV },
];

export const OrganizationsList = {
  TRD: '7fe84fae-87ec-42eb-b8a9-5f23dfbce2de',
};

export const DEFAULT_COLORS = ['#FF5733', '#33CC33', '#1895f5', '#FF99CC', '#CC33CC', '#89cff0', '#66CC00', '#88B04B', '#FF3300', '#FF5F00', '#808080'];

export const driverSimTypes = [
  { label: 'AVL', value: 'avl' },
  { label: 'TRD', value: 'trd' },
];

export const specItems: SelectItem<Spec>[] = [
  { label: 'GEN7', value: Spec.GEN7 },
  { label: 'GEN6', value: Spec.GEN6 },
  { label: 'GT3', value: Spec.GT3 },
];
